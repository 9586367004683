import React, { useContext, useEffect, useState } from "react";
import ProductSlider from "../Component/ProductSlider/ProductSlider";

import ProductSlider2 from "../Component/ProductSlider/ProductSlider2";
import ProductSlider3 from "../Component/ProductSlider/ProductSlider3";
import ProductSlider4 from "../Component/ProductSlider/ProductSlider4";
import ImageSlider from "../Component/ImageSlider/Slider";
import Section2 from "../Component/Sections/Section2";
import Section3 from "../Component/Sections/Section3";
import Section5 from "../Component/Sections/Section5";
import Banner1 from "../Component/Sections/Banner1";
import Section6 from "../Component/Sections/Section6";
import CustomerReview from "./CustomerReview/CustomerReview";
import BannerCalling_lc from "./CustomerReview/BannerCalling_lc";
import ProductPriceCarousel from "./HomepageLc/ProductPriceCarousel";
import BannerSlider1_Lc from "../Component/ItemCategoryViewer_LC/BannerSlider1_Lc";
import OurPurposeBanner_Lc from "../Component/ItemCategoryViewer_LC/OurPurposeBanner_Lc";
import WearTheTrend from "../Component/ItemCategoryViewer_LC/WearTheTrend";
import Banner1_Lc from "../Component/ItemCategoryViewer_LC/Banner1_Lc";
import CarouselLensLc from "../Pages/HomepageLc/CarouselLensLc";
import NameWithBanner_Lc from "../Component/ItemCategoryViewer_LC/NameWithBanner_Lc";
import ItemTopViewer_Lc from "../Component/ItemCategoryViewer_LC/ItemTopViewer_Lc";
import WishListTheme from "../Component/Sections/WishListTheme";
import MyContext from "../MyContext";
import FindThePerfectFit from "../Component/ItemCategoryViewer_LC/FindThePerfectFit";
import ContactLenseMore_Lc from "../Component/ItemCategoryViewer_LC/ContactLenseMore_Lc";
import ButItYourWay_Lc from "../Component/ItemCategoryViewer_LC/ButItYourWay_Lc";
import OurBrandsBanner_Lc from "../Component/ItemCategoryViewer_LC/OurBrandsBanner_Lc";
import ProductSliderImages_Lc from "../Component/ItemCategoryViewer_LC/ProductSliderImages_Lc";
import AsSeenOnBanner from "../Component/ItemCategoryViewer_LC/AsSeenOnBanner";
import AsSeenOnSharkTank from "../Component/ItemCategoryViewer_LC/AsSeenOnSharkTank";
import AsSeenOnKaranJohar from "../Component/ItemCategoryViewer_LC/AsSeenOnKaranJohar";
import TrendingSunglasses from "../Component/ItemCategoryViewer_LC/TrendingSunglasses";
import AquaColor from "../Component/ItemCategoryViewer_LC/AquaColor";
import ByOnWhatsapp from "./CustomerReview/ByOnWhatsapp";
import OurBrandsJohnJacobs from "../Component/ItemCategoryViewer_LC/OurBrandsJohnJacobs";
import ProductSliderImagesWithBanner_Lc from "../Component/ItemCategoryViewer_LC/ProductSliderImagesWithBanner_Lc";
import ProductReadersImagesWithBanner_Lc from "../Component/ItemCategoryViewer_LC/ProductReadersImagesWithBanner_Lc";
import ProductComputerBlu_Lc from "../Component/ItemCategoryViewer_LC/ProductComputerBlu_Lc";
import ProductWithContactLens from "../Component/ItemCategoryViewer_LC/ProductWithContactLens";
import SunglassSlider from "../Component/ItemCategoryViewer_LC/SunglassSlider";
import ShopBanner from "../../API/API_SERVISES/Shopbanner";
import ItemMaster from "../../API/API_SERVISES/ItemMaster";
import ItemGroup from "../../API/API_SERVISES/ItemGroup";
import Testimonial from "../Component/Testimonial/Testimonial";
// import WelcomePOPUP from "../Component/WelcomePOPUP/WelcomePOPUP";

const HomePage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the App component is mounted
    window.scrollTo(0, 0);
  }, []);

  const { wishLisHandle, setwishLisHandle, currentUser, shopBanner } = useContext(MyContext)

  const [advertisementBanner, setAdvertisementBanner] = useState([]);
  const [mainBanner, setMainBanner] = useState([]);
  const [itemGroup, setItemGroup] = useState([]);
  useEffect(() => {
    console.log("shopBanner", shopBanner)
  }, [shopBanner])

  // const fetchData = async () => {
  //   if (currentUser === true) {
  //     try {
  //       const data = await ShopBanner.GetShopBanners();
  //       // debugger
  //       if (data) {
  //         const filteredData = data.filter(item => item.imageBannerName.includes("Advertisement")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
  //         console.log("filteredData", filteredData);
  //         setAdvertisementBanner(filteredData);

  //         const mainfilterData = data.filter(item => item.imageBannerName.includes("FirstImage")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
  //         setMainBanner(mainfilterData)
  //       }
  //     } catch (error) {
  //       console.error('Error fetching shop banners:', error);
  //     }
  //   }
  // }

  const [block2Banner, setBlock2Banner] = useState([]);

  useEffect(() => {
    // fetchData();
    // debugger
    if (shopBanner.length > 0) {

      const filteredData = shopBanner.filter(item => item.imageBannerName.includes("Advertisement")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
      console.log("filteredData", filteredData);
      setAdvertisementBanner(filteredData);

      const mainfilterData = shopBanner.filter(item => item.imageBannerName.includes("FirstImage")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
      setMainBanner(mainfilterData)

      const filteredData1 = shopBanner.filter(item => item.imageBannerName.includes("Block2")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
      console.log("filteredData", filteredData1);
      setBlock2Banner(filteredData1);
    }

  }, [currentUser, shopBanner])

  useEffect(() => {
    if (currentUser === true) {

      const fetchData = async () => {
        const filteredData = [{
          fieldName: "showInAddBanner",
          operatorName: "Equal",
          compareValue: true
        }];
        try {
          const data = await ItemGroup.GetItemGroupWithFilter(filteredData);
          // console.log(data);
          setItemGroup(data);
          console.log("itemData", data)
        } catch (error) {
          console.error('Error fetching shop banners:', error);
        }
      }
      fetchData();
    }
  }, [currentUser])


  console.log(mainBanner);

  const [showwelcomepop, setshowwelcompop] = useState(false)


  useEffect(() => {
    setTimeout(() => {
      setshowwelcompop(true)
      // setTimeout(() => {
      //   setshowwelcompop(false)
      // }, 6000);
    }, 4000);
  }, [])

  return (
    <>
      <div style={{ position: "relative" }}>
        {wishLisHandle && (
          <div className="fixed-bottom">
            <WishListTheme />
          </div>
        )}
        {/* <ImageSlider /> */}
        <ProductPriceCarousel />

        {mainBanner && mainBanner.map((item, index) => (
          <Banner1_Lc key={index} item={item} />
        ))}

        <BannerSlider1_Lc />
        {/* <OurPurposeBanner_Lc /> changed by toshika */}

        <WearTheTrend />

        {advertisementBanner && advertisementBanner.map((item, index) => (
          <NameWithBanner_Lc key={index} item={item} />
        ))}

        {/* <AsSeenOnBanner />
        < AsSeenOnSharkTank />
        <AsSeenOnKaranJohar />
        <TrendingSunglasses /> 
        <AquaColor /> changed by toshika */}
        {/* <FindThePerfectFit /> changed by toshika */}

        {/* {block2Banner && block2Banner.map((item, index) => ( */}
        {/* //alert(`index ${index}`), */}
        < ContactLenseMore_Lc filteredData={block2Banner} />
        <p></p>
        {/* ))} */}

        {/* <ButItYourWay_Lc /> */}
        {/* <OurBrandsBanner_Lc /> */}
        {/* <ProductSliderImages_Lc idNumber={582} idsunglass={585} /> */}
        {/* <ProductSlider4 heading="Eye-Glasses" /> */}
        {/* <Section2 /> */}
        {/* <BannerSlider1_Lc/> */}
        {/* <Section5 heading="Guides" /> */}
        {/* <ProductSlider4 heading="Sun-Glasses" /> */}
        {/* <Banner1 /> */}
        {/* <ProductSlider3 />
      <ProductSlider2 /> */}
        {/* <Banner1 /> */}
        {/* <ProductSlider3 /> */}
        {/* <ProductSlider2 /> */}
        {/* <Section3 /> */}
        {/* <ProductSlider /> */}
        {/* <Section4 /> */}
        {/* <Section6 /> */}
        {/* <ItemTopViewer_Lc/> */}
        {/* <SocialMediaLink2/>  */}

        {itemGroup && itemGroup.map((item, index) => (
          <ProductSliderImages_Lc key={index} item={item} categoryData={item} idNumber={582} idsunglass={585} />
        ))}

        {/* <OurBrandsJohnJacobs /> */}

        {/* <ProductSliderImages_Lc idNumber={582} idsunglass={585} /> */}
        {/* <ProductSliderImagesWithBanner_Lc idNumber={582} /> */}
        {/* <ProductReadersImagesWithBanner_Lc idNumber={582} /> */}
        {/* <ProductComputerBlu_Lc idNumber={583} /> */}
        {/* <ProductWithContactLens idNumber={586} /> */}
        {/* <BannerCalling_lc /> */}
        {/* <CarouselLensLc idNumber={585}/> */}
        {/* <ByOnWhatsapp /> */}
        <Testimonial />
        <CustomerReview />
        {/* {showwelcomepop ? <WelcomePOPUP showwelcomepop={showwelcomepop} setshowwelcompop={setshowwelcompop} /> : null} */}

      </div>
    </>
  );
};

export default HomePage;
