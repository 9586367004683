import React, { useEffect, useState } from 'react'
import paymentDone from "../Assets/Images/order.png"
import paymentFail from "../Assets/Images/accept.png"
import PayStack from '../../API/API_SERVISES/PayStack'
import { Link, useNavigate } from 'react-router-dom'
import Transaction from '../../API/API_SERVISES/Transaction'
import Order from '../../API/API_SERVISES/Order'
import Payment from '../../API/API_SERVISES/Payment'
import CurrentDateAndTime from '../../API/API_SERVISES/CurrentDateAndTime'
import AddAddress from '../../API/API_SERVISES/AddAddress'
import BillSeriesMaster from '../../API/API_SERVISES/BillSeriesMaster'
import BillType from '../../API/API_SERVISES/BillType'


const PaymentResponse = () => {

    const [orderdetails, setOrderDetails] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        // var data = {
        //     "status": true,
        //     "message": "Verification successful",
        //     "data": {
        //         "id": 4099260516,
        //         "domain": "test",
        //         "status": "success",
        //         "reference": "re4lyvq3s3",
        //         "receipt_number": null,
        //         "amount": 40333,
        //         "message": null,
        //         "gateway_response": "Successful",
        //         "paid_at": "2024-08-22T09:15:02.000Z",
        //         "created_at": "2024-08-22T09:14:24.000Z",
        //         "channel": "card",
        //         "currency": "NGN",
        //         "ip_address": "197.210.54.33",
        //         "metadata": "",
        //         "log": {
        //             "start_time": 1724318098,
        //             "time_spent": 4,
        //             "attempts": 1,
        //             "errors": 0,
        //             "success": true,
        //             "mobile": false,
        //             "input": [],
        //             "history": [
        //                 {
        //                     "type": "action",
        //                     "message": "Attempted to pay with card",
        //                     "time": 3
        //                 },
        //                 {
        //                     "type": "success",
        //                     "message": "Successfully paid with card",
        //                     "time": 4
        //                 }
        //             ]
        //         },
        //         "fees": 10283,
        //         "fees_split": null,
        //         "authorization": {
        //             "authorization_code": "AUTH_uh8bcl3zbn",
        //             "bin": "408408",
        //             "last4": "4081",
        //             "exp_month": "12",
        //             "exp_year": "2030",
        //             "channel": "card",
        //             "card_type": "visa ",
        //             "bank": "TEST BANK",
        //             "country_code": "NG",
        //             "brand": "visa",
        //             "reusable": true,
        //             "signature": "SIG_yEXu7dLBeqG0kU7g95Ke",
        //             "account_name": null
        //         },
        //         "customer": {
        //             "id": 181873746,
        //             "first_name": null,
        //             "last_name": null,
        //             "email": "demo@test.com",
        //             "customer_code": "CUS_1rkzaqsv4rrhqo6",
        //             "phone": null,
        //             "metadata": null,
        //             "risk_action": "default",
        //             "international_format_phone": null
        //         },
        //         "plan": null,
        //         "split": {},
        //         "order_id": null,
        //         "paidAt": "2024-08-22T09:15:02.000Z",
        //         "createdAt": "2024-08-22T09:14:24.000Z",
        //         "requested_amount": 30050,
        //         "pos_transaction_data": null,
        //         "source": null,
        //         "fees_breakdown": null,
        //         "connect": null,
        //         "transaction_date": "2024-08-22T09:14:24.000Z",
        //         "plan_object": {},
        //         "subaccount": {}
        //     }
        // }
        FetchOrderDetails()
    }, [])

    const FetchOrderDetails = async () => {
        try {
            var PaymentdataInString = localStorage.getItem("PayStackPayment")
            var data = JSON.stringify(PaymentdataInString)

            var dataToSend = {
                reference: data.reference || "b0k5is059k"
            }
            var orderdata = await PayStack.VerifyPayment(dataToSend)
            console.log("orderData", orderdata)
            if (orderdata.data.status === true) {
                setOrderDetails(orderdata.data)
                if (orderdata?.data?.data?.status == "success") {
                    const userLogin = localStorage.getItem("accountLogin");
                    const userArray = userLogin ? JSON.parse(userLogin) : null;

                    const cartData = await Order.getCartList(userArray.d2Id);
                    debugger
                    const accAdd = await AddAddress.getAddress(userArray.d2Id);
                    const address = accAdd.find(item => item.isDefault == true);
                    
                    const billSeries = await BillSeriesMaster.getBillSeriesMaster();
                    const defaultSeries = billSeries.find(item => item.setDefaultSeriesForEcom == true);
                    const filteredData = [
                        {
                            fieldName: "IsDefault",
                            operatorName: "Equal",
                            compareValue: true
                        }
                    ]
                    const billTypeData = await BillType.GetFilteredBillType(filteredData);
                    const billData = { billTypeId: billTypeData ? (billTypeData[0].id || 0) : 0, billSeriesId: defaultSeries ? (defaultSeries?.id || 0) : 0 }

                    const response = await Transaction.GetTransRefNoForOrder(userArray, cartData, 241, address, billData); //Online
                    await Order.DeleteEcomCartByAccountId(userArray.d2Id)

                    const totalAmt = cartData.reduce((total, item) => {
                        return total + (item.quantity * item.itemPrice);  // Add the calculated amount for each item
                    }, 0);

                    // **************************** Save payment details *************

                    const dateTime = await CurrentDateAndTime.getCurrentDateTime();

                    let paymentData = {
                        ord1Id: 0,
                        orderNo: 0,
                        orderRefNo: response.referenceNumber,
                        date: dateTime,
                        amount: totalAmt,
                        apiId: 0,
                        apiName: "",
                        pgOrderId: "",
                        pgOrderStatus: "",
                        pgTrackingId: "",
                        pgBankRefNo: "",
                        pgFailureMessage: "",
                        pgPaymentMode: "",
                        pgCardName: "",
                        pgStatusCode: "",
                        pgStatusMessage: "",
                        transactionDetail: "",
                        remarks: "",
                    }
                    const insertedData = await Payment.insertPayment(paymentData);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    const backToMyOrderPage = () => {
        const tabName = "My Order"
        navigate("/admin", {
            state: {
                tabName
            }
        });
    }

    return (<>
        {orderdetails && Object.keys(orderdetails).length > 0 && (
            <div className='PaymentResponse'>
                {orderdetails?.data.status == "success" ? (
                    <img src={paymentDone} alt="" />
                ) : (
                    <img src={paymentFail} alt="" />
                )}
                <div className='paymentHeading'>
                    <h3>Payment Status : {(orderdetails?.data.status)?.toUpperCase()}</h3>
                    <p>Amount</p>
                    <h5>{orderdetails?.data.amount} KEN</h5>
                </div>
                <div className='paymentDetailsBox'>
                    <div className='paymentDetailsBox-inner' >
                        <div>
                            <label className='PaymentBoxInnerheading'>Amount Paid :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.amount} KES</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Charge Money :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.fees} KES</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Paymeny Method :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.channel}</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Currency :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.currency}</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Email :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.customer.email}</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Mobile :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.customer.phone}</label>
                        </div>
                    </div>
                    <div className='PaymentBoxButtonBox'>
                        <Link to="/" style={{ display: orderdetails?.data.status == "success" ? "" : "none" }} className='paymentDetailsBox-button print-btn-clr'>Print <i style={{ margin: "2px 0px 0px 6px" }} class="fa-solid fa-print"></i></Link>
                        {/* <Link to="/" style={{ backgroundColor: orderdetails?.data.status == "success" ? "" : "#de5605" }} className='paymentDetailsBox-button'>Back to Homepage</Link> */}
                        <div style={{ backgroundColor: orderdetails?.data.status == "success" ? "" : "#de5605" }} className='paymentDetailsBox-button' onClick={backToMyOrderPage}>Back to Homepage</div>
                    </div>

                </div>


            </div>
        )
        }
        <h2 style={{ textAlign: "center", color: "gray", marginBottom: "200px" }}>We’ve received your Order. Your Order number is 252.</h2>

    </>
    )
}

export default PaymentResponse