import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Navigation, Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module
import 'swiper/css/navigation';
import 'swiper/css/pagination'; // Import pagination styles

const Testimonial = () => {
    return (
        <section className="Testimonialcontainer">
            <Swiper
                slidesPerView={1}
                grabCursor={true}
                loop={true}
                pagination={true} // Enable pagination
                navigation={true} // Enable navigation
                modules={[Pagination, Navigation, Autoplay]} // Register modules
                autoplay={{ // Configure autoplay
                    delay: 3000, // 3 seconds delay
                    disableOnInteraction: false, // Autoplay continues after user interactions
                }}
                className="testimonial mySwiper"
            >
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/3m5x6B5/people6.jpg" alt="" className="image" />
                    <p>
                    I recently visited Pintoo Mobiles Shop and had a great experience. 
                    The store has a wide selection of mobile phones, accessories, and gadgets from various brands.
                     The staff was friendly and knowledgeable, providing useful advice without being pushy.
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Aanjali Verma</span>
                        <span className="job">Chhattisgarh</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/hFVbbty/people3.jpg" alt="" className="image" />
                    <p>
                    Overall, I would recommend Pintoo Mobiles Shop to anyone looking for a reliable mobile phone retailer with great customer service.
                     I'll definitely return for future purchases!
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Jaya Singh</span>
                        <span className="job">Chhattisgarh</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/Lh9gfFZ/people2.jpg" alt="" className="image" />
                    <p className='test-monial'>
                    The pricing is competitive, and I found some good deals on the latest models.
                    The store is well-organized, making it easy to browse through different options. 
                    The only downside was that some items were out of stock, but the staff was happy to check availability or offer alternatives.
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Ram Kumar</span>
                        <span className="job">Chhattisgarh</span>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default Testimonial;
