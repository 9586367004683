import React from 'react'

const CustomerReview = () => {
    return (
        <div>
            <div style={{ background: "#fff092" }}>
                <div className='customer-title-1'>
                    <h3 className='customer-main-title'>VIDEOS</h3>
                    <div className='link-youtube-frame'>
                        <div className="customer-link-detail">
                        <iframe className="frame-yt-video " src="https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="customer-link-detail">
                        <iframe className="frame-yt-video " src="https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="customer-link-detail">
                        <iframe className="frame-yt-video " src="https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerReview